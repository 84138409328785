// ImageDisplay.js
import React, { useRef, useState, useEffect, useCallback  } from 'react';
import { useParams, useNavigate, useSearchParams  } from 'react-router-dom';
import { addDays, subDays, addWeeks, subWeeks, addMonths, subMonths, addYears, subYears } from 'date-fns';
import { isMobile, isTablet, isDesktop } from 'react-device-detect';
import { format } from 'date-fns';

import './css/controls.css';
import './css/dialog.css';
import './css/ButtonPanel.css';
import './css/dateStrap.css';

import FeedbackDialog from './components/FeedbackDialog';
import DebugDisplay from './components/DebugDisplay';
import AboutDialog from './components/AboutDialog';
import Alert from './components/Alert';
import Clipboard from './components/Clipboard';
import DateStrap from './components/DateStrap';
import CalendarDialog from './components/Calendar';
import CalcMoment from './components/CalcMoment';
import ImageTransform from './components/TransformComponent';
import { renderDateControls, renderSpecialControls } from './components/Controls';

import config from './config.json';

const ImageDisplay = () => {
  // read from env.local or env vars
  const debug = process.env.REACT_APP_DEBUG_MODE;
  const apiAuthKey = process.env.REACT_APP_API_KEY;

  // ref vars
  const imageViewerRef = useRef(null);

  // state vars
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 932);
  const [isLandscapeView, setisLandscapeView] = useState(window.innerHeight <= 432);
  const [deviceType, setDeviceType] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [loaded, setLoaded] = useState(false);

  // show overlay dialogs
  const [showFeedbackDialog, setShowFeedbackDialog] = useState(false);
  const [showAboutDialog, setShowAboutDialog] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // read from config
  const [showNew, setShowNew] = useState(config.isNewVisible);

  const [copied, setCopied] = useState(false);

  // feedback state
  const [feedbackResponseSubmitted, setFeedbackResponseSubmitted] = useState(false);

  const [menuVisible, setMenuVisible] = useState(false);
  const [calendarVisible, setCalendarVisible] = useState(false);
  
  // Function to check if the date is in the format YYYY-MM-DD  
  function isValidDate (dateString, config) {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime()) &&
      date >= new Date(config.lowerDateBound) && date <= new Date(config.upperDateBound);
  };
  
  const detectDeviceType = () => {
    // Using react-device-detect
    let userAgentDeviceType = '';

    if (isMobile) {
      userAgentDeviceType = 'Mobile';
    } else if (isTablet) {
      userAgentDeviceType = 'Tablet';
    } else if (isDesktop) {
      userAgentDeviceType = 'Desktop';
    }

    const detectedDeviceType = `${userAgentDeviceType}`;
    setDeviceType(detectedDeviceType);
  };

    const handleEvent = async (action) => {
    if (! window.location.href.includes("localhost") && config.enableEvents) {
      try {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const response = await fetch(`${config.apiEventUrl}`, {
          method: 'POST',
          headers: {
            'Authorization': `${apiAuthKey}`
          },
          body: JSON.stringify({ // Convert object to JSON string
            "action": action,
            "viewport": `${width}x${height}`,
            "deviceType": `${deviceType}`,
            "url": window.location.href
          })
        });
      } catch (error) {
        console.log(`Error posting event`, error);
      }
    }
  };

  const { userDate: paramDate } = useParams();
  //console.log(userDate);
  const [searchParams] = useSearchParams();
  const queryDate = searchParams.get('date');
  if (queryDate) { handleEvent(`Visit by shared link`);}
  // Prefer the query parameter if available, otherwise fallback to the URL parameter
  let inputDate = queryDate || paramDate || config.defaultDate;



  // Check if the incoming inputDate is a valid date
  if (!isValidDate(inputDate, config) || inputDate.length !== 10) {
    inputDate = config.defaultDate;
  }

  const [selectedDate, setSelectedDate] = useState(inputDate);
  // to change url on button
  const navigate = useNavigate();

  useEffect(() => {
    fetchData(selectedDate);
  }, [selectedDate]);

  /*   useEffect(() => {
    if (imageViewerRef.current) {
      imageViewerRef.current.resetTransform();  // Call resetTransform in the child
    }
  }, [imageUrl]); */

  useEffect(() => {
    detectDeviceType();
    window.addEventListener('resize', detectDeviceType);

    return () => {
      window.removeEventListener('resize', detectDeviceType);
    };
    }, []);

  // api call for issueUrl
  const fetchData = async (date) => {
    setLoaded(false);
    setImageUrl('');
    document.title = `UKArchiveTV: ${selectedDate}`;
    try {
      const response = await fetch(`${config.apiUrl}${date}`, {
        method: 'GET',
        headers: {
          'Authorization': `${apiAuthKey}`,
        },
      });
      const data = await response.json();
      const newImageUrl = data.issueUrl ? (data.issueUrl === "missing" ? `${config.missingUrl}` : data.issueUrl) : `${config.missingUrl}`;
      setImageUrl(newImageUrl);
      navigate(`/${date}`);
    } catch (error) {
      console.error('Error fetching picUrl:', error);
    }  
  };

   const handleCopyLink = () => {
    // record event
    handleEvent(`Copy link pressed`);
    navigator.clipboard.writeText(window.location.origin + '/?date=' + selectedDate)
    .then(() => {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
       }, 5000);
    })
    .catch((error) => {
      console.error('Failed to copy:', error);
    });
  };

  // ensure other dialogs are closed if one is opened
  const handleFeedback = () => {
    handleEvent(`Feedback pressed`);
    handleAboutClose();
    setShowFeedbackDialog(true);
  };

  const handleAbout = () => {
    handleEvent(`About pressed`);
    handleFeedbackClose();
    setShowAboutDialog(true);
  };

  // close dialog and clear fields
  const handleFeedbackClose = () => {
    setShowFeedbackDialog(false);
    setFeedbackResponseSubmitted(false);
  };

  const handleAboutClose = () => {
    setShowAboutDialog(false);
  };

  const toggleMenu = () => {
    handleEvent(`toggleMenu pressed`);
    setMenuVisible(!menuVisible);
  };

  const toggleCalendar = () => {
    handleEvent(`Calendar pressed`);
    setCalendarVisible(!calendarVisible);
  };

  // Event listener to detect screen size changes
  window.addEventListener('resize', () => {
    setIsMobileView(window.innerWidth <= 932);
  });

  const moveDate = useCallback((direction, period) => {
    setLoaded(false);
    let newDate = new Date(selectedDate + ' 18:00:00');
    switch (period) {
      case "day":
        newDate = direction === 'next' ? addDays(newDate, 1) : subDays(newDate, 1);
        break;
      case "week":
        newDate = direction === 'next' ? addWeeks(newDate, 1) : subWeeks(newDate, 1);
        break;
      case "month":
        newDate = direction === 'next' ? addMonths(newDate, 1) : subMonths(newDate, 1);
        break;
      case "year":
        newDate = direction === 'next' ? addYears(newDate, 1) : subYears(newDate, 1);
        break;
      case "decade":
        newDate = direction === 'next' ? addYears(newDate, 10) : subYears(newDate, 10);
        break;
      default:
        break;
    }

    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
/*       if (imageViewerRef.current) {
        imageViewerRef.current.resetTransform();  // Call resetTransform in the child
      } */
    }
    setMenuVisible(false);
  }, [selectedDate]);

  const moveDateSpecial = useCallback((newDate, specialMoment) => {
    handleEvent(`${specialMoment} pressed`);
    newDate = CalcMoment(new Date(selectedDate + ' 18:00:00'), specialMoment);
    
    if (!(newDate >= new Date(config.lowerDateBound) && newDate <= new Date(config.upperDateBound))) {
      setShowAlert(true);
      setLoaded(true);      
      setTimeout(() => {
        setShowAlert(false);
       }, 5000);
    } else {
      setSelectedDate(newDate.toISOString().split('T')[0]);
    }
    setMenuVisible(false);
  }, [selectedDate]);

  const timer = setTimeout(() => {
    setShowNew(false);
  }, 7000); 

  const [smallDateStrap, setSmallDateStrap] = useState(isMobileView);

  const handleDateClick = () => {
    handleEvent(`Date Strap pressed`);
    setSmallDateStrap(!smallDateStrap);
  };


  return (   
    <div className="base" style={{ flexDirection: isMobileView ? "row" : "column" }}>

    {/* Date controls */}
    {/* Show sliding menu toggle button on mobile */}
    {isMobileView && ! isLandscapeView && (
      <div className={`controls-menu ${menuVisible ? 'show-menu' : ''}`}>
      {/* Special controls */}
      <div className="special-controls-wrapper">
      <div className="button-container">
        {renderSpecialControls(moveDateSpecial, selectedDate)}    
      </div>  
      </div>
    </div>
    )}

    <DateStrap 
      selectedDate={selectedDate}
      handleAbout={handleAbout}
      menuVisible={menuVisible}
      toggleMenu={toggleMenu}
      smallDateStrap={smallDateStrap}
      isMobileView={isMobileView}
      showNew={showNew}
      toggleCalendar={toggleCalendar}
      calendarVisible={calendarVisible}
      handleCopyLink={handleCopyLink}
      handleFeedback={handleFeedback}
      handleDateClick={handleDateClick}
    />
    
    <CalendarDialog
        calendarVisible={calendarVisible}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        lowerDateBound={config.lowerDateBound}
        upperDateBound={config.upperDateBound}
    />
    { debug === "true" ? 
    <DebugDisplay 
        config={config} 
        selectedDate={selectedDate} 
        imageUrl={imageUrl} 
        loaded={loaded} 
        showAlert={showAlert} 
    /> : null}

    <div className="controls-wrapper row"> {/* ref={targetDivRef} */}
      {renderDateControls(moveDate, 'Decade', 'decade')}
      {renderDateControls(moveDate, 'Year', 'year')}
      {renderDateControls(moveDate, 'Month', 'month')}
      {renderDateControls(moveDate, 'Week', 'week')}
      {renderDateControls(moveDate, 'Day', 'day')}
    </div>

    {!isMobileView && (
    <div className="controls-wrapper row">
      <div className="special-button-container row">
        {renderSpecialControls(moveDateSpecial, selectedDate)}    
      </div>
    </div>
    )}

    <FeedbackDialog 
      showFeedbackDialog={showFeedbackDialog} 
      handleFeedbackClose={handleFeedbackClose}
      feedbackResponseSubmitted={feedbackResponseSubmitted}
      apiFeedbackUrl={config.apiFeedbackUrl}
      apiAuthKey={apiAuthKey}
    />

    <AboutDialog 
      showAboutDialog={showAboutDialog} 
      handleAboutClose={handleAboutClose}
      aboutUrl={config.aboutUrl}
    />

    <Alert showMessage={showAlert} />

    <Clipboard showMessage={copied} />
    <div id="image-container">
    <ImageTransform ref={imageViewerRef} 
      imageUrl={imageUrl} 
      loaded={loaded}
      setLoaded={setLoaded}
      selectedDate={selectedDate}
      handleEvent={handleEvent}
    />
    </div>
    </div>
  );
};

export default ImageDisplay;
