// DateStrap.js
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLink } from '@fortawesome/free-solid-svg-icons';

import { format } from 'date-fns';

const DateStrap = ({ selectedDate, handleAbout, menuVisible, smallDateStrap, toggleMenu, isMobileView, showNew, toggleCalendar, calendarVisible, handleCopyLink, handleFeedback, handleDateClick }) => {
  const [reportComment, setReportComment] = useState('');
  
  console.log(smallDateStrap);

  return (
    ! smallDateStrap ? (
      <div className="header-container">
          <div className="links-container">
              <span className="feedback-link" onClick={handleAbout}>About</span>
              {isMobileView && (
              <button className="show-menu-button" onClick={toggleMenu}>☰</button>
              )}
          </div>
          {showNew && (<div className="fade-text"><span>&lt;&lt; NEW</span></div>)}
          {isMobileView  
          ? (<div className="date-strap"><h1 onClick={handleDateClick}>{format(new Date(selectedDate), 'EEE MMM d, yyyy')}</h1></div>) 
          : (<div className="date-strap"><h1 onClick={handleDateClick}>{format(new Date(selectedDate), 'EEE MMMM d, yyyy')}</h1></div>)
          }
          
          <div className="links-container">
              <span className="feedback-link" onClick={handleFeedback}>Feedback</span>
          <div className="links-container-row">
          <div className="copy-container" onClick={toggleCalendar} >
          <FontAwesomeIcon className={`cal-link ${calendarVisible ? 'active' : ''}`} icon={faCalendarAlt} />
          </div>
          <div className="copy-container">
          <FontAwesomeIcon className="copy-link" icon={faLink} onClick={handleCopyLink} />
          </div>
          </div></div>
      </div>
    ) :
    ( <div className="date-landscape"><span onClick={handleDateClick}>{format(new Date(selectedDate), 'EEE MMM d, yyyy')}</span>
              <div className="copy-container" onClick={toggleCalendar} >
          <FontAwesomeIcon className={`cal-link ${calendarVisible ? 'active' : ''}`} icon={faCalendarAlt} />
          
          </div>
          </div> )
    );
};

export default DateStrap;
